
export const handleAPIError = (error: any) => {
  if (error.response) {
    const { data, status } = error.response;
    return {
      data,
      status,
    };
  } else if (error.request) {
    const { data, status } = error.request;
    return {
      data,
      status,
    };
  } else {
    return error.message;
  }
};
