/* eslint-disable import/order */
import React, { ComponentType, useEffect, useState } from 'react';

import { DidomiSDK } from '@didomi/react';
import { DFPManager } from '@dsch/react-dfp';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import cookies from 'next-cookies';
import { NextSeo } from 'next-seo';
import { MapProvider } from 'react-map-gl/maplibre';
import { Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';

import { LegacyBanner } from '../components/Toolkit/LegacyBanner/LegacyBanner';
import { BuyingBudgetProvider } from '../contexts/buyingBudgetContext';
import {
  UserDetailsContext,
  UserDetailsContextType,
} from '../contexts/userDetailsContext';
import { isUser } from '../helpers/auth/auth';
import { setAppElement } from '../helpers/components/Modal';
import {
  getBuyingBudgetInfoFromUserResponse,
  getBuyingBudgetValueFromUserResponse,
} from '../helpers/realms/utils';
import { GOOGLE_RECAPTCHA_KEY } from '../helpers/reCaptcha/reCaptcha';
import { GlobalStyles } from '../helpers/styles/GlobalStylesHelper';
import { DefaultTheme } from '@dist-property-frontend/configs';
import {
  CONVERT_KEY,
  DIDOMI_API_KEY,
  DIDOMI_NOTICE_ID,
  ENVIRONMENT_NAME,
  GOOGLE_TAG_MANAGER,
  HUBVISOR_ID,
  INTEGRATION_TESTING,
  RAYGUN_API_KEY,
} from '../helpers/utils';
import { montserrat, openSans } from '@dist-property-frontend/fonts';

import { useRaygun } from '../hooks/useRaygun';
import { privateUser } from '../mocks/privateUser';

import '../css/toastify.css';

import useConsentDidomi from '../hooks/useConsentDidomi';

import SEO from '../next-seo.config';

setAppElement('#__next');

// Limit reporting to approx 10% of users (there's a cost issue)
const rateLimit = Math.random() >= 0.9;
export function reportWebVitals(metric: any) {
  if (!INTEGRATION_TESTING && ENVIRONMENT_NAME === 'production' && rateLimit) {
    const { id, name, startTime, value, label } = metric;
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({
        event: `web-vitals`,
        event_category:
          label === 'web-vital' ? 'Web Vitals' : `Next.js custom metric`,
        event_action: name,
        nonInteraction: true, // avoids affecting bounce rate.
        event_startTime: startTime,
        event_value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers,
        event_label: id,
      });
    }
  }
}

const ToastContainer: ComponentType<any> = dynamic(
  import('react-toastify').then((module) => module.ToastContainer),
  {
    ssr: false,
    loading: () => <></>,
  },
);

const showLegacyFlag = false;

Router.events.on('routeChangeComplete', (url: string) => {
  if ((window as any).dataLayer) {
    (window as any).dataLayer.push({
      url,
      event: 'Pageview',
    });
  }
});

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

function MyApp(props: any) {
  const dangerouslySetAllPagesToNoIndex = Boolean(
    ENVIRONMENT_NAME !== 'production',
  );
  const nextSeoProps = { ...SEO, dangerouslySetAllPagesToNoIndex };
  const {
    Component,
    pageProps,
    router: { asPath },
  } = props;
  const { daftCookies, user } = pageProps;

  const [hasBuyingBudget, setHasBuyingBudget] = useState(false);
  const [hasCreatedLead, setHasCreatedLead] = useState(false);
  const [buyingBudgetIsSelling, setBuyingBudgetIsSelling] = useState(false);
  const [instructionLeadCreated, setInstructionLeadCreated] = useState(false);
  const [buyingBudgetVersion, setBuyingBudgetVersion] = useState(undefined);
  const [reactDFPAdsReadyToLoad, setReactDFPAdsReadyToLoad] = useState(false);

  const [userDetails, setUserDetails] = useState<UserDetailsContextType>({
    loggedInAgentId: undefined,
    created_at: Math.floor(Date.now() / 1000),
    isUserLoggedIn: false,
    userId: undefined,
    segments: undefined,
    name: undefined,
    email: undefined,
    phone: undefined,
  });

  const { DidomiConfig, allowRaygun, onReady, onConsentChanged, allowConvert } =
    useConsentDidomi();

  const { debugMode, enablePulse } = useRaygun(allowRaygun, 30);

  const checkNotDevAndRaygunEnabled =
    (ENVIRONMENT_NAME === 'staging' || ENVIRONMENT_NAME === 'production') &&
    allowRaygun;

  const handleHubvisorAdRefresh = (adSlotIds: any[] = []) => {
    const Hubvisor = (window as any).Hubvisor;
    const googletag = (window as any).googletag;
    if (googletag) {
      let adSlotsToRefresh: any[] | null = null;
      let hubvisorRefreshVal = null;
      if (adSlotIds.length > 0) {
        hubvisorRefreshVal = adSlotIds;
        googletag.cmd.push(function () {
          adSlotsToRefresh = googletag
            .pubads()
            .getSlots()
            .filter((slot: any) =>
              adSlotIds.some((id) => slot.getSlotElementId() === id),
            );
        });
      }

      if (Hubvisor) {
        Hubvisor('refresh', hubvisorRefreshVal, function () {
          googletag.cmd.push(function () {
            googletag.pubads().refresh(adSlotsToRefresh);
          });
        });
      } else {
        googletag.cmd.push(function () {
          googletag.pubads().refresh(adSlotsToRefresh);
        });
      }
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const ctx = {
        res: {
          locals: {
            user: user,
          },
        },
      };
      const isUserLoggedIn = await isUser(
        ctx as any,
        asPath && asPath.indexOf('/agents') === 0 ? 'agents' : 'daft',
      );

      const time = isUserLoggedIn ? user?.user_details?.createdAt : Date.now();

      setUserDetails({
        loggedInAgentId: isUserLoggedIn ? user?.agent_id : undefined,
        created_at: Math.floor(time / 1000),
        isUserLoggedIn: isUserLoggedIn,
        userId: isUserLoggedIn
          ? INTEGRATION_TESTING
            ? privateUser.user_details.userId
            : user?.user_details?.userId
          : undefined,
        segments: isUserLoggedIn ? user?.user_details?.segments : undefined,
        name: isUserLoggedIn
          ? INTEGRATION_TESTING
            ? privateUser.name
            : user?.name
          : undefined,
        email: isUserLoggedIn
          ? INTEGRATION_TESTING
            ? privateUser.email
            : user?.email
          : undefined,
        phone: isUserLoggedIn
          ? INTEGRATION_TESTING
            ? privateUser.user_details.contactInfo.phone
            : user?.user_details?.contactInfo.phone
          : undefined,
        verifiedPhone: isUserLoggedIn
          ? INTEGRATION_TESTING
            ? privateUser.user_details.verifiedPhone
            : user?.user_details?.verifiedPhone
          : undefined,
        smsVerified: isUserLoggedIn
          ? INTEGRATION_TESTING
            ? true
            : user?.sms_verified
          : undefined,
      });

      setHasBuyingBudget(
        isUserLoggedIn &&
          user &&
          getBuyingBudgetInfoFromUserResponse(user, 'buying_budget'),
      );
      setHasCreatedLead(
        isUserLoggedIn &&
          user &&
          getBuyingBudgetInfoFromUserResponse(user, 'mortgage_lead_created'),
      );
      setBuyingBudgetIsSelling(
        isUserLoggedIn &&
          user &&
          getBuyingBudgetInfoFromUserResponse(user, 'buying_budget_is_selling'),
      );
      setInstructionLeadCreated(
        isUserLoggedIn &&
          user &&
          getBuyingBudgetInfoFromUserResponse(user, 'instruction_lead_created'),
      );
      setBuyingBudgetVersion(
        isUserLoggedIn &&
          user &&
          getBuyingBudgetValueFromUserResponse(user, 'buying_budget_version'),
      );
    };

    fetchUser();

    //unregister service workers once next-pwa is remove from Daft.
    //This is to avoid users to face caching issues
    const unregister = async () => {
      if ('serviceWorker' in navigator) {
        await navigator.serviceWorker
          .getRegistrations()
          .then(async (registrations) => {
            for (const registration of registrations) {
              await registration.unregister();
            }
          });
      }
    };

    unregister();

    const slotsLoadedHandler = (loadedSlotsList: any[]) => {
      if (loadedSlotsList.length > 0) {
        return handleHubvisorAdRefresh(loadedSlotsList);
      }
    };
    DFPManager.on('slotsLoaded', slotsLoadedHandler);
    setReactDFPAdsReadyToLoad(true);

    return () => {
      DFPManager.off('slotsLoaded', slotsLoadedHandler);
      setReactDFPAdsReadyToLoad(false);
    };
  }, []);

  // eslint-disable-next-line no-console
  if (debugMode) console.log('Debug Mode Enable 🐞🐛️🛠️💻️️');

  const initRaygun = () => {
    return {
      __html: `
      !function(a,b,c,d,e,f,g,h){a.RaygunObject=e,a[e]=a[e]||function(){
        (a[e].o=a[e].o||[]).push(arguments)},f=b.createElement(c),g=b.getElementsByTagName(c)[0],
        f.async=1,f.src=d,g.parentNode.insertBefore(f,g),h=a.onerror,a.onerror=function(b,c,d,f,g){
        h&&h(b,c,d,f,g),g||(g=new Error(b)),a[e].q=a[e].q||[],a[e].q.push({
        e:g})}}(window,document,"script","//cdn.raygun.io/raygun4js/raygun.min.js","rg4js");

        rg4js('apiKey', '${RAYGUN_API_KEY}');
        rg4js('enableCrashReporting', true);
        rg4js('enablePulse', ${enablePulse});
        rg4js('whitelistCrossOriginDomains', ['dist-property-frontend-daft.dsch.dev', 'dist-property-frontend-daft.daft.ie']);
        rg4js('options', { 
          debugMode: ${debugMode},
          ignore3rdPartyErrors: true 
        });`,
    };
  };

  const initGoogleTagManager = () => {
    return {
      __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER}');
    `,
    };
  };

  const initHubvisor = () => {
    return {
      __html: `
      const hubvisorPubId = '${HUBVISOR_ID}';
      !function(h,u,b,v,i,s,o,r){function n(r){var c=console.error;function o(n){try{n(r)}catch(n){c(n)}}c(r);var n=u[h].cmd;u[h]=function(n,r,c){o(c)},u[h].cmd=n;for(var i=0;i<u[h].cmd.length;++i)o(u[h].cmd[i]);u[h].cmd={push:o}}u[h]=u[h]||function(){var n=arguments;u[h].cmd.push(function(){u[h].apply(null,n)})},u[h].cmd=u[h].cmd||[];var r=setTimeout(n.bind(null,new Error("Hubvisor loading timeout !")),s||3e3);function c(){r&&(clearTimeout(r),r=0)}var o=b.createElement(v);o.async=!0,o.src="https://cdn.hubvisor.io/wrapper/"+i+"/hubvisor.js",b.head.appendChild(o),o.addEventListener("error",function(){c(),n(new Error("Hubvisor loading failed !"))}),u[h].cmd.push(function(){c()});}('Hubvisor',window,document,'script',hubvisorPubId)
      `,
    };
  };

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="twitter:app:country"
          key="twitter:app:country"
          content="IE"
        />
        <meta
          name="twitter:app:name:iphone"
          key="twitter:app:name:iphone"
          content="Daft.ie"
        />
        <meta
          name="twitter:app:id:iphone"
          key="twitter:app:id:iphone"
          content="346547065"
        />
        <meta
          name="twitter:app:url:iphone"
          key="twitter:app:url:iphone"
          content="https://apps.apple.com/ie/app/daft-ie/id346547065"
        />
        <meta
          name="twitter:app:name:ipad"
          key="twitter:app:name:ipad"
          content="Daft.ie"
        />
        <meta
          name="twitter:app:id:ipad"
          key="twitter:app:id:ipad"
          content="346547065"
        />
        <meta
          name="twitter:app:url:ipad"
          key="twitter:app:url:ipad"
          content="https://apps.apple.com/ie/app/daft-ie/id346547065"
        />
        <meta
          name="twitter:app:name:googleplay"
          key="twitter:app:name:googleplay"
          content="Daft - Buy, Rent or Share Ireland Real Estate"
        />
        <meta
          name="twitter:app:id:googleplay"
          key="twitter:app:id:googleplay"
          content="com.daft.ie"
        />
        <meta
          name="twitter:app:url:googleplay"
          key="twitter:app:url:googleplay"
          content="https://play.google.com/store/apps/details?id=com.daft.ie&hl=en"
        />
        <meta
          name="facebook-domain-verification"
          key="facebook-domain-verification"
          content="288025jmza1mvfrsjupbmyrsf2yiwt"
        />
      </Head>

      {checkNotDevAndRaygunEnabled && (
        <Script id="raygun" dangerouslySetInnerHTML={initRaygun()} />
      )}

      <Script
        id="convertScript"
        type="text/javascript"
        src={`//cdn-4.convertexperiments.com/js/${CONVERT_KEY}.js`}
        crossOrigin="anonymous"
      />

      {!INTEGRATION_TESTING && (
        <DidomiSDK
          apiKey={DIDOMI_API_KEY}
          noticeId={DIDOMI_NOTICE_ID}
          iabVersion={2}
          gdprAppliesGlobally={true}
          config={DidomiConfig}
          onReady={onReady}
          onConsentChanged={onConsentChanged}
        />
      )}
      <Script id="gtag-mgr" dangerouslySetInnerHTML={initGoogleTagManager()} />
      {!INTEGRATION_TESTING && (
        <Script id="hubvisorScript" dangerouslySetInnerHTML={initHubvisor()} />
      )}

      {/* eslint-disable-next-line @next/next/no-sync-scripts */}
      <script
        id="recaptchaScript"
        src={`https://www.google.com/recaptcha/enterprise.js?render=${GOOGLE_RECAPTCHA_KEY}`}
      />

      <NextSeo {...nextSeoProps} />
      {showLegacyFlag && asPath && asPath.indexOf('/agents') !== 0 && (
        <LegacyBanner />
      )}

      <ToastContainer
        transition={Slide}
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />

      <ThemeProvider theme={DefaultTheme}>
        <UserDetailsContext.Provider value={userDetails}>
          <BuyingBudgetProvider
            hasBuyingBudget={hasBuyingBudget}
            hasCreatedLead={hasCreatedLead}
            buyingBudgetIsSelling={buyingBudgetIsSelling}
            instructionLeadCreated={instructionLeadCreated}
            buyingBudgetVersion={buyingBudgetVersion}
          >
            <MapProvider>
              <GlobalStyles />
              {/*
               * Setting Next-Fonts as CSS variables so they can then be used in CSS
               * The openSans and montserrat variables are imported from & defined in the fonts/font.ts file
               * Look at Next Font documentation for more info: https://nextjs.org/docs/pages/building-your-application/optimizing/fonts
               */}
              <style jsx global>{`
                :root {
                  --font-open-sans: ${openSans.style.fontFamily};
                  --font-montserrat: ${montserrat.style.fontFamily};
                }
              `}</style>
              <Component
                {...{
                  ...pageProps,
                  ...(userDetails.isUserLoggedIn &&
                    INTEGRATION_TESTING && {
                      user: privateUser,
                    }),
                }}
                path={asPath}
                daftCookies={daftCookies}
                reactDFPAdsReadyToLoad={reactDFPAdsReadyToLoad}
              />
            </MapProvider>
          </BuyingBudgetProvider>
        </UserDetailsContext.Provider>
      </ThemeProvider>
    </>
  );
}

setAppElement('#__next');

MyApp.getInitialProps = async ({ Component, ctx }: any) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  const Cookies = cookies(ctx);

  const daftCookies = Cookies.daftCC;

  return {
    pageProps: {
      ...pageProps,
      user: ctx?.res?.locals.user,
      daftCookies: daftCookies
        ? JSON.parse((daftCookies as string).replace(/\\/g, ''))
        : {},
      smsVerified:
        ctx.req?.query?.verified && ctx.res?.locals.user?.sms_verified,
    },
  };
};

export default MyApp;
