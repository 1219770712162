import {
  ENVIRONMENT_NAME,
  RECAPTCHA_SITE_KEY_V3,
  RECAPTCHA_TESTING_KEY_V3,
  INTEGRATION_TESTING,
} from '../../helpers/utils';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const getRecaptchaToken = (
  action: string,
  key: string,
  integration?: boolean,
): Promise<string> => {
  if (integration) {
    return Promise.resolve('');
  } else {
    return new Promise((resolve, reject) => {
      try {
        (window as any).grecaptcha?.enterprise.ready(() => {
          return (window as any).grecaptcha.enterprise
            .execute(key, {
              action,
            })
            .then((token: string) => {
              resolve(token);
            });
        });
      } catch (error) {
        reject(error instanceof Error ? error : new Error(String(error)));
      }
    });
  }
};

// set correct recaptcha key for environment
const GOOGLE_RECAPTCHA_KEY =
  ENVIRONMENT_NAME !== 'production' || INTEGRATION_TESTING
    ? RECAPTCHA_TESTING_KEY_V3
    : RECAPTCHA_SITE_KEY_V3;

// generate new recaptcha token
const generateRecaptchaToken = async (pageName: string) => {
  const recaptchaToken = await getRecaptchaToken(
    pageName,
    GOOGLE_RECAPTCHA_KEY,
  );
  return recaptchaToken;
};

export { GOOGLE_RECAPTCHA_KEY, generateRecaptchaToken, getRecaptchaToken };
